<template>
    <div>  
      <div class="card">
        <a-skeleton v-if="loading" />
  
        <div v-else>
          <a-empty v-if="list.length === 0" />
  
          <table v-else class="table">
            <tr class="header">
              <td>任务名称</td>
              <td>创建人</td>
              <td>创建时间</td>
              <td>状态</td>
              <td class="center">操作</td>
            </tr>
  
            <tr v-for="item in list" :key="item.id" class="row">
              <td>{{ item.name }}</td>
              <td>{{ item.createBy }}</td>
              <td>{{ item.createAt }}</td>
              <td>
                <span v-if="item.status == 'reviewing'">审批中</span>
                <span v-if="item.status == 'approved'">审批通过</span>
                <span v-if="item.status == 'rejected'">已驳回</span>
                <span v-if="item.status == 'cancelled'">已取消</span>
              </td>
              <td class="control center">
                <span @click="approve(item)" v-if="item.status == 'reviewing'">通过</span>
                <span
                  v-if="item.status == 'reviewing'"
                  style="
                    margin: 0 4px;
                    height: 1em;
                    width: 2px;
                    background-color: #0667fd;
                  "
                ></span>
                <span @click="reject(item)" v-if="item.status == 'reviewing'">驳回</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  function fetchUserList(params) {
    return request({
        url: '/review/list',
        params
    })
  }
  function reviewUpdate(data) {
    return request({
        url: '/review/update',
        method: 'post',
        data
    })
  }

  
  import request from '@/api/request.js'
  export default {
    data() {
      return {
        userList: [],
        loading: false,
        list: [],
      };
    },
  
    mounted() {
      this.getList();
    },
  
    methods: {
      getList() {
        this.loading = true;
        fetchUserList({
          pageNum: 1,
          pageSize: 999,
          name: this.name,
          code: this.code,
          mobile: this.mobile,
        })
          .then((res) => {
            if (Array.isArray(res.list)) {
              this.list = Object.freeze(res.list).filter(v=>v.status);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
      approve(item) {
        const that = this;
        this.$confirm({
          title: "确认通过？",
          okText: "是",
          okType: "danger",
          cancelText: "否",
          onOk() {
            reviewUpdate({ id: item.id, status:"approved" }).then(() => {
              that.getList();
            });
          },
        });
      },
      reject(item) {
        const that = this;
        this.$confirm({
          title: "确认驳回？",
          okText: "是",
          okType: "danger",
          cancelText: "否",
          onOk() {
            reviewUpdate({ id: item.id, status:"rejected" }).then(() => {
              that.getList();
            });
          },
        });
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
    .label {
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    color: #9ca4b1;
    margin-bottom: 12px;
  }
  .card {
    background-color: #fff;
    box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
    border-radius: 20px;
    padding: 40px;
  }
  .query {
    display: flex;
    align-items: center;
    gap: 10px;
  
    input {
      width: 177px;
      height: 40px;
      padding: 0 12px;
      background: #f8f9fb !important;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 500;
    }
    input::placeholder {
      font-size: 16px;
      font-weight: 500;
      color: #abb3c0;
    }
    select {
      width: 177px;
      height: 40px;
      padding: 0 12px;
      background: #f8f9fb !important;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 500;
    }
  
    .button-group {
      .primary-button {
        width: 71px;
        height: 38px;
        line-height: 100%;
        color: #fff;
        background: linear-gradient(146deg, #0c99ff 0%, #0560fd 100%);
        box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.32);
        border-radius: 6px 0px 0px 6px;
      }
  
      .reset-button {
        width: 71px;
        height: 38px;
        background: #fff;
        border-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top: 3px solid #eff1f6;
        border-right: 3px solid #eff1f6;
        border-bottom: 3px solid #eff1f6;
        box-sizing: border-box;
      }
    }
  }
  
  .add {
    margin-left: auto;
    width: 156px;
    height: 38px;
    line-height: 100%;
    color: #fff;
    background: linear-gradient(146deg, #0c99ff 0%, #0560fd 100%);
    box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.32);
    border-radius: 6px;
  }
  
  .table {
    width: 100%;
    .header {
      opacity: 0.7;
  
      td {
        padding: 16px;
        background: #ebf2fc;
        font-weight: 400;
        color: #9ca4b1;
        font-size: 16px;
      }
      td:first-child {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
      }
      td:last-child {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
      }
    }
  
    .row {
      border-bottom: 1px solid rgba(207, 220, 238, 0.71);
  
      td {
        padding: 16px;
      }
    }
  }
  
  .status {
    display: flex;
    align-items: center;
    gap: 6px;
    img {
      height: 16px;
    }
  }
  
  .control {
    color: #0667fd;
    cursor: pointer;
  }
  </style>